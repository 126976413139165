import styled, { css } from 'styled-components';
import { Text } from '../../util/typography';
import * as colors from '../../util/colors';
import Link from 'gatsby-link';

export const HEADER_NAV_HEIGHT = '46px';
export const HEADER_NAV_WIDTH = '320px';
export const MAGIC_BP = '640px';

export const HeaderMenu = styled.div`
    display: flex;
    background-color: white;
    margin-top: 20px;
    border-radius: 4px;
    color: ${colors.blueberry};
    border: 1px solid ${colors.pebble};
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);

    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    overflow: hidden;
    // safety for sections with no title
    min-height: ${HEADER_NAV_HEIGHT};
    z-index: 6;

    ${props =>
        props.isOpen &&
        css`
            border-radius: 4px 4px 0 0;

            @media (min-width: ${MAGIC_BP}) {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 4px;
            }
        `};
`;

export const MenuNavButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    color: ${colors.blueberry};

    &:hover {
        cursor: pointer;
    }

    ${Text.Button} {
        color: inherit;
    }

    svg {
        flex: 0 0 15px;
    }

    @media (min-width: ${MAGIC_BP}) {
        flex-grow: 0;
        flex-basis: 320px;
        flex-basis: ${HEADER_NAV_WIDTH};
        min-width: 0;
    }
`;

export const TruncateLabelWrapper = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 8px;
    padding: 12px 20px;
`;

export const DropdownIconContainer = styled.div`
    height: 100%;
    padding: 12px 15px;
    border-left: 1px solid ${colors.pebble};
    display: flex; 
    align-items:center;
`