import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { InView } from 'react-intersection-observer';
import { Heading, Text } from '../util/typography';
import { MAGIC_BP } from './components/PiHeaderMenuStyled';
import PiFooter from './components/PiFooter';
import Container from './components/Container';
import { getScrollbarSize } from './utils';
import * as colors from '../util/colors';
import slideIndicator from '../assets/icon-slide-indicator.svg';
import { createLocalScaleFunction } from '../util/functions';
import minusIcon from '../assets/icons/btn-collapse-dark.svg'
import plusIcon from '../assets/icons/btn-open-dark.svg'
import { TertiaryButton } from '../util/CTA';

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"


const HEADER_FOOTER_HEIGHT = '66px';
const HEADER_FOOTER_HEIGHT_ANCHOR_PADDING = '90px';

const createMarkup = (value) => {
    return { __html: value };
};


export const Main = styled.main`

    a {
        text-decoration: underline;
    }

    html & {
        background: ${colors.white};
        margin-top: 0;
        margin-bottom: 0;
    }

    margin: ${HEADER_FOOTER_HEIGHT} 0;
    padding: 40px 0 20px 0;

    /* maybe 768px, this is using the same "magic breakpoint" as the navbar. */
    @media (min-width: ${MAGIC_BP}) {
        padding: 60px 84px 30px 84px;
    }

    .strong, [stylecode="bold"], strong, b, .Bold, .bold {
        font-weight: 600;
    }

    [stylecode="underline"], .underline {
        text-decoration: underline;
    }

    .italics {
        font-style: italic;
    }

    sup {
        vertical-align: super;
        top: 0px;
    }
`

const TertiaryButtonLink = styled(TertiaryButton)`
    padding-top: 20px;
    @media (min-width: ${MAGIC_BP}) {
        padding-top: 30px;
    }
    padding-bottom: 0;
    & > span {
        padding: 0;
    }
    &::after {
        display: none;
    }
`;
const ThickSeparator = styled.div`
    height: 6px;
    margin: 30px 0 40px;
    background-color: ${colors.htmlPiBlack};
`

const PiHeadingWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-top: ${createLocalScaleFunction(20, 320, 30, 952)};
    margin-bottom: 30px;

    & > button {
        appearance: none;
        -webkit-appearance: none;
        border: 0;
        padding: 0;
        background: transparent;
    }
`;

const PiAfter = styled.div`
    align-self: center;

    a {
        color: ${colors.blueberry};
        font-family: 'Arial' !important;
        font-size: 18px;
        font-weight: bold;
        position: relative;
        bottom: 3px;
    }
    
    a:hover {
        color: ${colors.blueberryDark};
    }
`;

const PiHeading = styled(Heading.H3)`
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: 'Arial' !important;
    font-size: 18px;
    font-weight: bold;
    color: ${colors.htmlPiBlack};

    &:before {
        content: '';
        background-image: url(${({ isOpen }) => (isOpen ? minusIcon : plusIcon)});
        background-repeat: no-repeat;
        background-position: center;
        height: 22px;
        width: 22px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        flex: 0 0 22px;
}
`
const SectionWrapper = styled.div`

    p, li, span, td {
        font-family: 'Arial' ;
        color: ${colors.htmlPiBlack};
    }
    .sectionHeader {
        padding-bottom: 16px;
    }

    & [id] {
        scroll-padding-top: ${HEADER_FOOTER_HEIGHT_ANCHOR_PADDING};
        scroll-margin-top: ${HEADER_FOOTER_HEIGHT_ANCHOR_PADDING}; 
    }
`

const hasScrollOverflow = 'has-overflow';
const leftScrollOverflow = 'left-overflow-scroll';
const rightScrollOverflow = 'right-overflow-scroll';

// This is the container for 
const SectionBody = styled.div`
    --table-header-bg-color: ${colors.htmlPiBlack};
    --table-header-text-color: ${colors.white};
    --table-header-border-color: ${colors.concrete};
    --table-footer-bg-color: ${colors.mist}; 
    --table-body-border-color: ${colors.pebble};
    --table-body-alt-row-color: ${colors.white};
    --table-border-radius: 8px;
    --scrollbar-size: ${props => props.scrollbarSize}px;

    section .component .title {
        margin: 30px 0 10px;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        color: ${colors.ink};
        display: block;
    }

    /* TODO: this is a bit of a hack to get things looking correctly where subsections [Ex- 2.4 ends up all bold if this is removed.] */
    section .component .title .text {
        ${Text.getTextStylesByType('body')}
        ${Text.getLinkStyles}
        ${Text.getBoldStyles}
        ${Text.getBodySuperscriptStyles}
    }

    section .title,
    section .highlight {
        display: none;
    }

    .eyebrow {
        ${Text.getTextStylesByType('eyebrow')}
    }

    .mobile-scroll-label {
        display: none;

        .eyebrow {
            color: ${colors.slate};
        }
    }
    .table-container.${hasScrollOverflow} {
        .mobile-scroll-label {
            display: flex;
            align-items: center;
            padding-bottom: 17px;

            .slide-indicator {
                height: 16px;
                width: 44.2px;
                display: inline-block;
                margin-right: 9.9px;
                background-repeat: no-repeat;
                background-image: url(${slideIndicator});
            }
        }
    }

    p.strong {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: 0.4px;
        color: ${colors.ink};  

        display: flex;
        align-items: center;
        text-align: center;
        margin: 40px -1em 21px -1em;



        &:before,
        &:after {
          content: "";
          flex: 1 1 5%; /* The flex basis here will force the starting value of the lines. */
          height: 1px;
          margin: 0 1em;
          background: var(--table-header-bg-color);
        }
    }

    /* TODO: these may be superfluous */
    ul[stylecode="Disc"],
    ul[stylecode="disc"] {
        list-style: disc;
    }
    ul[stylecode="Circle"],
    ul[stylecode="circle"] {
        list-style: circle;
    }

    .contents-list {
        list-style: none;
        color: ${colors.blueberry};
        font-size: 16px;
        font-weight: 600;
    }

    .contents-sub-list {
        list-style: none;
        color: ${colors.blueberry};
        font-size: 16px;
        font-weight: normal;

        li {
            margin: 5px 0; /* TODO: This isn't right */
        }
    }

    ::not(.table-wrapper) .generated-img {
        border-radius: var(--table-border-radius);
        border: 1px solid var(--table-header-border-color);
        padding: 20px 20px 20.2px;
        margin: 20px 0 0;
        background-color: ${colors.white};
    }
    .generated-img img {
        width: 100%;
        object-fit: contain;
    }

    .overflow-shadow {
        position: relative;
    }

    .overflow-shadow.${leftScrollOverflow}::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 20px;
        background: linear-gradient(
            to left,
            rgba(38, 38, 38, 0),
            rgba(38, 38, 38, 0.26)
        );

        border-top-left-radius: var(--table-border-radius);
        ${(props => props.scrollbarSize > 0) ? 'margin-bottom: var(--scrollbar-size)' : 'border-bottom-left-radius: var(--table-border-radius)' };
    }

    .overflow-shadow.${rightScrollOverflow}::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 20px;
        background: linear-gradient(
          to right,
          rgba(38, 38, 38, 0),
          rgba(38, 38, 38, 0.26)
        );
        
        border-top-right-radius: var(--table-border-radius);
        ${(props => props.scrollbarSize > 0) ? 'margin-bottom: var(--scrollbar-size)' : 'border-bottom-right-radius: var(--table-border-radius)' };
    }

    .table-wrapper.header {

        thead {
            th {
                padding: 16px 20px;
                color: var(--table-header-text-color);
                background-color: var(--table-header-bg-color);
                border-right: 1px solid var(--table-header-border-color);
                border-bottom: 1px solid var(--table-header-border-color);
                vertical-align: middle;
                font-size: 20px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.2;
                letter-spacing: normal;
            }
            
            tr:last-child {
                border-bottom: none;
            }
        }

        tbody {
            tr:first-child {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                td:first-child {
                    border-top-left-radius: 0;
                }
                td:last-child {
                    border-top-right-radius: 0;
                }                 
            }
        }
    }

    .table-wrapper.footer {
        tfoot {

            font-size: 12px;
            font-weight: 300;

            tr {   
                td {
                    background-color: var(--table-footer-bg-color);
                    color: var(--table-header-bg-color);
                    padding: 8px 20px;
                    border-bottom: solid 1px var(--table-header-bg-color);
                }
            }

        }

        tbody {
            tr:last-child {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                td:first-child {
                    border-bottom-left-radius: 0;
                }
                td:last-child {
                    border-bottom-right-radius: 0;
                }                 
            }
        }
    }
    
    .table-wrapper.recent-major-changes table {
        tr td {
            padding: 10px 25px;
        }

        tr td:first-child {
            width: 100%;
        }

        tr td:last-child {
            font-weight: 500;
        }
    }

    .table-wrapper:not(.recent-major-changes) td {
        min-width: 160px;
    }
    .table-wrapper {

        .xmChange {
            color: white;
        }

        overflow-x: auto;

        table {
            width: 100%;
            border-top: solid 1px var(--table-header-bg-color);
            border-right: solid 1px var(--table-header-bg-color);
            border-left: solid 1px var(--table-header-bg-color);
            border-spacing: 0;

            caption {
                display: none;
            }

            tbody {
                tr {
                    td {
                        padding: 16px 20px;
                        border-right: 1px solid var(--table-header-bg-color);
                        border-bottom: 1px solid var(--table-header-bg-color);
                    }
                    td:last-child {
                        border-right: none;
                    }
                }
                tr:last-child {
                    border-bottom: none;
                }
            }
        }
    }

    .footnotes {
        margin: 23px 0 0;
        font-size: 12px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: ${colors.htmlPiBlack};

        > div {
            padding: 7px 0;

            .footnote-symbol {
                padding-right: 10px;
            }
        }
    }
`

const TopHeading = styled(Heading.H4)`
    font-family: 'Arial';
    font-size: 22px;
    font-weight: bold;   
    color: ${colors.htmlPiBlack};
`
const SectionContainer = styled.div`

`

const ItemWrapper = styled.div`
    :first-of-type {

        > span:first-child {
            margin-top: 26px;
        }

        .section-wrapper {
            columns: 2 350px;
            column-gap: 40px;
        }
    }
`

export const InteractivePI = ({ piData, piKey }) => {
    
    //console.log('pi data', piData, piKey);
    const [menuLabel, setMenuLabel] = useState('Jump to section');
    const [scrollSize, setScrollSize] = useState(0);
    const [clickedAccordion, _setClickedAccordion] = useState(null);
    const clickedAccordionRef = useRef(clickedAccordion)
    const [allAccordionsClosed, setAllAccordionsClosed] = useState(false);

    const setClickedAccordion = update => {
        clickedAccordionRef.current = update
        _setClickedAccordion(update)
    }

    useEffect(() => {
        setScrollSize(getScrollbarSize());
    }, [setScrollSize, getScrollbarSize]);

    useEffect(() => {

        var elems = document.querySelectorAll(".table-wrapper");

        const onScroll = (event) => {       
            const current = event.srcElement;
            const { scrollWidth = 0, scrollLeft = 0, offsetWidth = 0 } = current || {};

            const container = current.parentElement;
            container.classList.remove(leftScrollOverflow);
            container.classList.remove(rightScrollOverflow);

            // Need to find the full table container to set the has-scroll class.
            const parentContainer = container.parentElement;
            parentContainer.classList.remove(hasScrollOverflow);

            if (scrollLeft > 0) {
                container.classList.add(leftScrollOverflow);
                parentContainer.classList.add(hasScrollOverflow);
            }
            if (scrollLeft + offsetWidth < scrollWidth) {
                container.classList.add(rightScrollOverflow);
                parentContainer.classList.add(hasScrollOverflow);
            }
        };

        // Watch browser resize to update containers classes when scroll area changes.
        const onResize = () => {
            elems.forEach((elem) => { 
                onScroll({ srcElement: elem }); 
            });   
        };

        window.addEventListener('resize', onResize);

        elems.forEach((elem) => { 
            // initialize state of each table-wrapper.
            onScroll({ srcElement: elem }); 

            // create scroll event listener.
            elem.addEventListener('scroll', onScroll);
        });

        return () => {
            window.removeEventListener('resize', onResize);
            elems.forEach((elem) => { 
                elem.removeEventListener('scroll', onScroll);
            });      
        };
    }, []);

    
    useEffect(() => {
        // Create an object to hold the accordion state of each section.
        let accordionStates = {};
        piData.forEach(section => {
            if (section.id) {
                accordionStates[section.id] = false;
            }
        });

        accordionStates['highlights'] = true;
        accordionStates['contents'] = true;
        setClickedAccordion(accordionStates);
    }, []);

    useEffect(() => {
        if (!clickedAccordion) return;
        for (const key of Object.keys(clickedAccordion)) {
            if (clickedAccordion[key]) {
                setAllAccordionsClosed(false);
                return;
            }
        }
        setAllAccordionsClosed(true);

    }, [clickedAccordion]);


    const accordionIsOpen = id => {
        if (clickedAccordionRef.current && clickedAccordionRef.current[id]) return true
        return false;
    };

    function accordionHandler(id) {
        let updateValue = {};
        updateValue[id] = !accordionIsOpen(id);

        setClickedAccordion({ ...clickedAccordionRef.current, ...updateValue });
    };

    // detects hash change
    const hashHandler = () => {
        // gets anchor link section & subsection ids
        let sectionId = (window.location.hash).split(".")[0].substring(1); //example: S1
        let subSectionId = (window.location.hash).split(".")[1];
        
        // scrolls to selected section
        let selectTargetSection;
        if(!subSectionId) {
            selectTargetSection = document.getElementById(sectionId)
        } else {
            selectTargetSection = document.getElementById(sectionId + '.' + subSectionId)
        }
        
        console.log('SectionID:', sectionId, subSectionId, selectTargetSection);

        if (selectTargetSection) {
            // opens accordion if it already isn't open
            if(accordionIsOpen(sectionId)===false){
                accordionHandler(sectionId);
            }

            selectTargetSection.scrollIntoView();
        }
    }

    useEffect(() => {
        window.addEventListener('hashchange', hashHandler, false);

        return () => {
            window.removeEventListener('hashchange', hashHandler)
        }
    }, [])


    const updateAllAccordions = (expanded) => {
        let updated = { ...clickedAccordion };
        for (const key of Object.keys(updated)) {
            updated[key] = expanded;
        }

        setClickedAccordion({ ...updated });
    };

    return (
        <>

            <Main>
                <Container>
                    <TopHeading>Prescribing Information (PI) for KEYTRUDA® (pembrolizumab) Injection 100mg</TopHeading>
                    <TertiaryButtonLink tagType='button' label={allAccordionsClosed ? 'Expand All Sections' : 'Collapse All Sections'} 
                                        aria-expanded={!allAccordionsClosed} onClick={() => updateAllAccordions(allAccordionsClosed)} />
                    {piData.map((section, index) => {
                        return (
                        <ItemWrapper key={index}>
                            <PiHeadingWrapper>
                                <PiHeading as='button' isOpen={accordionIsOpen(section.id)} dangerouslySetInnerHTML={{ __html: section.title}} data-design-category={"accordion_vd"}
                                       onClick={() => accordionHandler(section.id)} aria-expanded={accordionIsOpen(section.id)} />
                                {Boolean(section.afterHTML) && <PiAfter dangerouslySetInnerHTML={{ __html: section.afterHTML}}></PiAfter>}
                            </PiHeadingWrapper>
                            <SectionContainer  role="region" aria-label={`open accordian ${section.altTitle}`} aria-hidden={!accordionIsOpen(section.id)} className="raprap section-container" key={index} 
                                               style={(accordionIsOpen(section.id)) ? { display: 'block' } : { display: 'none' }}>
                                <SectionWrapper className="section-wrapper" >
                                    <InView as="div" onChange={(inView, entry) => { if (inView) setMenuLabel(section.navLabel)}}>
                                        <SectionBody scrollbarSize={scrollSize}>
                                            <Text.Body dangerouslySetInnerHTML={createMarkup(section.body)}></Text.Body>
                                        </SectionBody>
                                    </InView>
                                </SectionWrapper>
                                <ThickSeparator />
                            </SectionContainer>
                        </ItemWrapper>  
                    )})}
                </Container>
            </Main>
            <PiFooter/>
        </>
    )
};