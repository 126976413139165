import React from 'react';
import styled from 'styled-components';
import Container from './Container';
import { PrimaryButton } from '../../util/CTA';
import WhiteArrow from '../../assets/arrow-up-white.svg';

const { PI_PDF_URL } = require('../constants');
const HEADER_FOOTER_HEIGHT = '64px';

const Footer = styled.header`
    position: fixed;
    right: 0; 
    bottom: 0;
    left: 0;
    height: ${HEADER_FOOTER_HEIGHT};
    display: flex;
    align-items: end;
    pointer-events: none;   /* Allow anchor clicks from page content below element. */
    a {
        max-width: 992px;
        margin: auto;
        margin-top: 20px;
    }
`;

const FooterContent = styled(Container)`
    pointer-events: auto;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: end;
    pointer-events: none;
`;

const BackToTopButton = styled.div`
    background-color: rgba(38,38,38,0.8);
    height: 44px;
    width: 44px;
    border-radius: 50%;
    cursor: pointer;
    pointer-events: all;
    
    p {
        font-size: 10px;
        font-weight: 600;
        color: white;
        margin: 4px 0 0 0;
    }

    :before {
        content: '';
        background-image: url(${WhiteArrow});
        background-repeat: no-repeat;
        background-position: center;
        height: 15px;
        width: 13px;
        display: flex;
        margin: auto;
        margin-top: 7px;
    }
`

const TextContainer = styled.div`
    display:flex;
    justify-content: center;
`

const DownloadButton = styled(PrimaryButton)`
    pointer-events: all;
`;

const PiFooter = () => {

    const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
      };

    return (
        <Footer>
            <FooterContent>
                <DownloadButton label={"Prescribing Information PDF"} download={true} url={PI_PDF_URL}/>
                <BackToTopButton onClick={scrollToTop}>
                    <TextContainer><p>TOP</p></TextContainer>
                </BackToTopButton>
            </FooterContent>
        </Footer>
    );
};

export default PiFooter;
