import React from 'react';
import styled from 'styled-components';
import Container from './Container';

import { PrimaryButton} from '../../util/CTA';

const { PI_PDF_URL } = require('../constants');
const HEADER_FOOTER_HEIGHT = '64px';

const Footer = styled.header`
    position: absolute;
    right: 0; 
    top: 0;
    left: 0;
    height: ${HEADER_FOOTER_HEIGHT};
    display: flex;
    align-items: end;
    pointer-events: none;   /* Allow anchor clicks from page content below element. */
`;

const FooterContent = styled(Container)`
    pointer-events: auto;
    margin-top: 20px;
`;

const PiFooter = () => {

    return (
        <Footer>
            <FooterContent>
                <PrimaryButton label={"Prescribing Information PDF"} download={true} url={PI_PDF_URL}/>
            </FooterContent>
        </Footer>
    );
};

export default PiFooter;
