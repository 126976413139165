import styled from 'styled-components';

const Container = styled.div`
    font-family: 'proxima-nova';
    color: #262626;
    margin: 0 auto;
    padding: 0 20px;
    max-width: 992px;
`;

export default Container