import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Helmet } from 'react-helmet';
import ComponentWrapper from '../layout/ComponentWrapper';
import ViewSDKClient from './ViewSDKClient';

import * as CTA from '../../util/CTA';
import { createLocalScaleFunction } from '../../util/functions';
import { Heading, Text } from '../../util/typography';


const { ADOBE_VIEWER_SDK_URL, VIEWER_CONFIG } = require('./constants');

// More information:
//  * https://www.adobe.io/apis/documentcloud/dcsdk/docs.html?view=view
//  * https://documentcloud.adobe.com/view-sdk-demo/index.html#/view/FULL_WINDOW/Bodea%20Brochure.pdf
//  * https://github.com/adobe/pdf-embed-api-samples/tree/master/More%20Samples/React%20Samples

// TODO: how should this size itself correctly?

const SizedComponentWrapper = styled(ComponentWrapper)`
    height: ${props => props.height};
    box-shadow: 0 0 23px 0 rgba(0, 0, 0, 0.1);
    margin: ${createLocalScaleFunction(20, 320, 30, 952)} 0px;
`

const PdfPreview = styled.div`
    height: 100%;
    width: 100%;
`

const ButtonWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`

const Summary = styled.div``

const SummaryTitle = styled(Heading.H4)`
    margin-bottom: 10px;
`

const AdobePdfViewer = props => {

    useEffect(() => {
        const viewSDKClient = new ViewSDKClient();
        if(props.renderSized){
        viewSDKClient.ready().then(() => {
            viewSDKClient.previewFile(props.id, props.fileUrl, props.metadata, VIEWER_CONFIG, props.renderSized);
        });
        } else{
        viewSDKClient.ready().then(() => {
            viewSDKClient.previewFile(props.id, props.fileUrl, props.metadata, VIEWER_CONFIG);
            });
        }
    }, []);

    const componentTheme = (props.theme) || (props.sectionTheme === 'dark') ? 'light' : 'dark';

    return (
        <>
            <Helmet>
                {/* This will import the script from adobe for use when the component is used. */}
                <script type="text/javascript" src={ADOBE_VIEWER_SDK_URL}></script>
            </Helmet>
            <SizedComponentWrapper height={props.height}>
                <PdfPreview id={props.id} />
            </SizedComponentWrapper>

            { (props.primaryAction ) && (
                <ButtonWrapper>
                    { props.primaryAction && (
                        <CTA.PrimaryButton
                            theme={componentTheme}
                            url= {props.primaryAction.url}
                            label= {props.primaryAction.label}
                        />
                    )}
                </ButtonWrapper>
            )}

            {(props.summary) && 
                <Summary>
                    <SummaryTitle>Summary</SummaryTitle>
                    <Text.Body>{props.summary}</Text.Body>
                </Summary>}

        </>
    );
};

/* Metadata: Object
    fileName: the name of the file - ex: myPdf.pdf
    id: a guid file id */

AdobePdfViewer.defaultProps = {
    id: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    fileUrl: PropTypes.string.isRequired,
    metadata: PropTypes.object,
    viewerConfig: PropTypes.object
}

export default AdobePdfViewer;
